import { ApolloClient, DataProxy, useApolloClient } from "@apollo/client";
import {
  HomeownerProjectQueryVariables,
  ProjectDetailFragment,
  ProjectIdByProjectIdFragment,
  ProjectIdByProjectIdFragmentDoc,
  ProjectIdByProjectStageFragment,
  ProjectIdByProjectStageFragmentDoc,
  ProjectStageDetailFragment,
  SearchBy,
  useHomeownerProjectQuery,
} from "src/generated/graphql-types";

type QueryType = ProjectDetailFragment | ProjectStageDetailFragment;

interface QueryMetaData<QueryType> {
  options: Pick<DataProxy.Fragment<any, any>, "fragment" | "fragmentName">;
  cachePrefix: "StandaloneProject" | "ProjectStage" | "Unit" | "Activity";
  getProjectId: (item: QueryType | null) => string | undefined;
}

/**
 * Mapping of how to search the cache depending on the `SearchBy` value provided.
 */
const metaDataBySearchLevel: Record<SearchBy, QueryMetaData<QueryType>> = {
  PROJECT: {
    options: { fragment: ProjectIdByProjectIdFragmentDoc },
    cachePrefix: "StandaloneProject",
    getProjectId: (project) => (project as ProjectIdByProjectIdFragment)?.id,
  },
  STAGE: {
    options: { fragment: ProjectIdByProjectStageFragmentDoc },
    cachePrefix: "ProjectStage",
    getProjectId: (stage) => (stage as ProjectIdByProjectStageFragment)?.project?.id,
  },
};

/**
 * Wrapped version of useHomeownerProjectQuery which will first check the cache to see if we already know the project id
 * associated with the request id. If we do, we can issue a query that will be fulfilled by the cache and render more quickly.
 * @param searchBy Type of search to be performed based on the information currently available to the caller
 * @param id ID of the associated entity indicated by `searchBy`
 * @param skip If true, skips running useHomeownerProjectQuery
 */
export function useHomeownerProjectSearchQuery(searchBy: SearchBy, id: string, skip: boolean = false) {
  const client = useApolloClient();
  const projectId = findProjectIdInCache(client, searchBy, id);
  let searchVariables: HomeownerProjectQueryVariables = projectId
    ? { searchBy: SearchBy.Project, id: projectId }
    : { searchBy, id };
  return useHomeownerProjectQuery({ variables: searchVariables, skip });
}

// Exported for testing
export function findProjectIdInCache(client: ApolloClient<any>, searchBy: SearchBy, id: string) {
  const metaData = metaDataBySearchLevel[searchBy];
  try {
    // NOTE: readFragment throws an exception if the cached data does cannot satisfy the data
    // being requested by the fragment. See: https://www.apollographql.com/docs/react/caching/cache-interaction/#readfragment
    const cachedItem = client.readFragment({
      ...metaData.options,
      id: `${metaData.cachePrefix}:${id}`,
    });
    return metaData.getProjectId(cachedItem);
  } catch (e) {
    return undefined;
  }
}
