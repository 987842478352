import { AuthViewProps, HomeboundUser } from "@homebound/auth-components";
import { useMediaQuery } from "@material-ui/core";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import {
  Button,
  Icon,
  PageContent,
  QueryResultHandler,
  SelectField,
  TooltipV2,
} from "src/components";
import { ModalWrapper } from "src/components/v2/organisms/ModalWrapper";
import { Breakpoints, Css, Palette } from "src/Css";
import {
  CreateWarrantyItemTypeFragment,
  CreateWarrantyProjectContactFragment,
  CreateWarrantyQuery,
  SaveWarrantyTicketAvailabilityInput,
  SaveWarrantyTicketItemInput,
  Scalars,
  useCreateWarrantyQuery,
  useCreateWarrantyTicketMutation,
} from "src/generated/graphql-types";
import { ProjectRouteProps, warrantyRoute } from "src/routes";
import {
  getStorageKey,
  numericMonthDateFormat,
  setStorageKey,
} from "src/utils";
import { Availability } from "./components/createWarrantySection/Availability.section";
import { ContactInfo } from "./components/createWarrantySection/ContactInfo.section";
import { RequestDetails } from "./components/createWarrantySection/RequestDetails.section";

const sections = ["Request Details", "Availability", "Contact Info"] as const;
type section = (typeof sections)[number];
const storageKey = "createWarrantyPage";

type FormState = {
  items: Pick<
    SaveWarrantyTicketItemInput,
    "attachments" | "description" | "type"
  >[];
  projectContactIds: string[];
  availabilities: SaveWarrantyTicketAvailabilityInput[];
  // Creating this to store the downloadUrl for assets as they haven't been uploaded yet
  assetMap: Record<string, string>;
  title: string;
};

export function CreateWarrantyPage({ user }: AuthViewProps) {
  const { projectId } = useParams<ProjectRouteProps>();
  const result = useCreateWarrantyQuery({ variables: { projectId } });

  return QueryResultHandler<CreateWarrantyQuery>({
    result,
    render: ({
      homeownerProject: { contacts },
      warrantyTicketItemTypesDetail,
    }) => (
      <CreateWarrantyView
        projectId={projectId}
        contacts={contacts}
        itemTypes={warrantyTicketItemTypesDetail}
        user={user!}
      />
    ),
  });
}

type CreateWarrantyViewProps = {
  projectId: string;
  contacts: CreateWarrantyProjectContactFragment[];
  itemTypes: CreateWarrantyItemTypeFragment[];
  user: HomeboundUser;
};

function CreateWarrantyView({
  contacts,
  projectId,
  itemTypes,
  user,
}: CreateWarrantyViewProps) {
  const [createWarrantyTicket, { loading: saving }] =
    useCreateWarrantyTicketMutation();
  // use sessionStorage to prefill the form if available
  const sessionStorageFormData =
    getStorageKey<
      Pick<
        FormState,
        "availabilities" | "projectContactIds" | "items" | "assetMap"
      >
    >(storageKey);
  const [form, setForm] = useState<FormState>(() => {
    const {
      projectContactIds = [],
      availabilities = [],
      items = [],
      assetMap = {},
    } = sessionStorageFormData ?? {};
    return {
      availabilities,
      projectContactIds,
      items,
      assetMap: assetMap,
      title: `Warranty Request ${numericMonthDateFormat(new Date())}`,
    };
  });
  const [currentSection, setCurrentSection] =
    useState<section>("Request Details");
  const sectionEls = useRef<HTMLDivElement[]>(new Array(sections.length));
  const [showErrors, setShowErrors] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const history = useHistory();
  const isMobile = useMediaQuery(Breakpoints.xs);
  const requestDetailsRef = useRef<{
    validate: () => void;
  }>(null);

  useEffect(() => {
    const observers: IntersectionObserver[] = [];
    if (
      sectionEls.current[0] &&
      sectionEls.current[1] &&
      sectionEls.current[2]
    ) {
      for (let i = 0; i < sectionEls.current.length; i++) {
        const sectionEl = sectionEls.current[i];
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              setCurrentSection(sections[i]);
              // If we are at the bottom of the page and we scroll up toggle availability as it was probably already in view
            } else if (
              i === sectionEls.current.length - 1 &&
              currentSection === sections[sectionEls.current.length - 1]
            ) {
              setCurrentSection(sections[i - 1]);
            }
          },
          {
            threshold: 0.6,
          },
        );
        observer.observe(sectionEl);
        observers.push(observer);
      }
    }
    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, [sectionEls]);

  // Persist updates to form data in sessionStorage
  useEffect(() => {
    setStorageKey(storageKey, form);
  }, [form]);

  async function onSubmit() {
    if (formIsValid(form)) {
      // Submit the form
      const { projectContactIds, availabilities, items, title } = form;
      await createWarrantyTicket({
        variables: {
          input: {
            projectContactIds,
            availabilities,
            items: items.map((i) => ({
              ...i,
              id: undefined,
              warrantyTicketId: undefined,
              status: undefined,
              homeownerVisible: true,
              product: undefined,
            })),
            projectId,
            type: undefined,
            id: undefined,
            title,
            homeownerVisible: true,
            attachments: undefined,
            urgent: undefined,
            assigneeIds: undefined,
          },
        },
      });

      // Clear out the sessionStorage
      setStorageKey(storageKey, {});
      setSuccessModalOpen(true);
    } else {
      // Show validation errors
      setShowErrors(true);
      requestDetailsRef.current?.validate();
    }
  }

  return (
    <PageContent xss={Css.ifXs.mxPx(-24).$}>
      <div
        css={
          Css.dn.ifXs.db.fixed.w100.bgWhitePure
            .z(1001)
            // setting this uniquely to only show on teh bottom of the header
            .add(
              "boxShadow",
              "0px 2px 16px -16px rgba(53, 53, 53, 0.03), 0px 4px 8px 0px rgba(53, 53, 53, 0.08);",
            ).bt.bTaupe.$
        }
      >
        <div css={Css.wPx(180).ma.$}>
          <SelectField
            options={sections.map((s) => s)}
            selectedOptions={[currentSection]}
            onChange={([s]) => {
              const index = sections.indexOf(s as any);
              if (sectionEls.current[index]) {
                sectionEls.current[index].scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
            triggerOverrides={Css.bsNone.py1.$}
          />
        </div>
      </div>
      <div css={Css.w100.mtPx(60).mb5.$}>
        <div css={Css.f14.fw7.lh("22px").add("letterSpacing", "unset").tc.$}>
          Submit a warranty request
        </div>
        <div
          css={Css.mt1.f24.fw4.lh("40px").add("letterSpacing", "0.48px").tc.$}
        >
          Let's gather some details about your request
        </div>
      </div>
      <div
        css={
          Css.df.w100.mb("calc(100vh - 406px)").ifXs.mb("calc(100vh - 456px)").$
        }
      >
        <div
          css={
            Css.ptPx(12).prPx(115).df.fdc.gap3.sticky.topPx(72).hfc.ifXs.dn.$
          }
        >
          {sections.map((s, i) => {
            const selected = s === currentSection;
            return (
              <div
                css={
                  Css.py1.px2.f16.fw3
                    .lh("28px")
                    .gray800.cursorPointer.nowrap.if(selected).black.fw5.bgTaupe
                    .br4.$
                }
                key={s}
                onClick={() => {
                  if (sectionEls.current[i]) {
                    sectionEls.current[i].scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                }}
              >
                {s}
              </div>
            );
          })}
        </div>
        <div css={Css.df.fdc.fg1.$}>
          {sections.map((s, i) => {
            const hasError =
              showErrors &&
              ((s === "Availability" && form.availabilities.length === 0) ||
                (s === "Contact Info" && form.projectContactIds.length === 0));
            return (
              <div
                css={
                  Css.add("scrollMarginTop", "122px").ifXs.add(
                    "scrollMarginTop",
                    "142px",
                  ).$
                }
                ref={(element) => {
                  sectionEls.current[i] = element!;
                }}
              >
                {hasError ? (
                  <div
                    css={
                      Css.mb2.f14.fw4
                        .lh("24px")
                        .add("letterSpacing", "0.56px")
                        .ttu.bgRed600.whitePure.df.add(
                          "scrollMarginTop",
                          "122px",
                        )
                        .ifXs.add("scrollMarginTop", "142px").tc.$
                    }
                    aria-invalid="true"
                  >
                    <div css={Css.mxPx(4).df.aic.$}>
                      <Icon
                        icon="errorExclamation"
                        size="16"
                        viewBox="0 0 16 16"
                      />
                    </div>
                    {`${s} is required`}
                  </div>
                ) : (
                  <div
                    css={
                      Css.mb2.f14.fw4.lh("24px").add("letterSpacing", "0.56px")
                        .ttu.gray800.df.aic.ifXs.jcc.$
                    }
                  >
                    {s}
                    {s === "Contact Info" && (
                      <TooltipV2
                        title={
                          <>
                            <div>
                              Add warranty contacts for direct warranty
                              communication;
                            </div>
                            <div>
                              project contacts still receive standard messages.
                            </div>
                          </>
                        }
                        placement="top"
                      >
                        <div css={Css.df.aic.jcc.$}>
                          <Icon icon="info" color={Palette.Purple600} />
                        </div>
                      </TooltipV2>
                    )}
                  </div>
                )}
                {getStepComponent({
                  section: s,
                  form,
                  setForm,
                  contacts,
                  projectId,
                  itemTypes,
                  user,
                  requestDetailsRef,
                })}
              </div>
            );
          })}
          <div css={Css.maxw("342px").ifXs.mx3.maxw("unset").$}>
            <Button
              type="submit"
              onClick={onSubmit}
              size="large"
              disabled={saving}
            >
              Submit Request
            </Button>
          </div>
        </div>
      </div>
      {successModalOpen && (
        <ModalWrapper>
          <div>
            <div css={Css.my6.mx8.ifXs.mx3.$}>
              <div
                css={
                  Css.f32.fw4.black
                    .lh("48px")
                    .add("letterSpacing", "0.64px")
                    .ifXs.f24.lh("40px")
                    .add("letterSpacing", "0.48px").$
                }
              >
                Successfully submitted!
              </div>
              <div
                css={
                  Css.mt3.f16.fw7
                    .lh("24px")
                    .gray800.add("letterSpacing", "unset").$
                }
              >
                Next Steps:
              </div>
              <ol
                css={
                  Css.plPx(20)
                    .mt2.f14.fw3.lh("32px")
                    .gray700.add("letterSpacing", "unset").$
                }
              >
                <li>
                  We'll determine whether your request falls within your home's
                  warranty.
                </li>
                <li>
                  Next, we'll see if there's additional information we need from
                  you.
                </li>
                <li>
                  Then, to the extent that the request is covered by the
                  warranty, we'll coordinate with trades to come during one of
                  your preferred times.
                </li>
                <li>
                  The trade will come during your preferred time after you
                  confirm.
                </li>
                <li>
                  If the request is covered by the warranty, and once we've
                  completed the work we'll follow-up with you to inform you of
                  our completion.
                </li>
              </ol>
            </div>
            <div
              css={
                Css.p3.bt.bGray300.df.ifXs.bTransparent.pb6.absolute.bottom0
                  .w100.$
              }
            >
              <div css={Css.mla.ifXs.ml0.w100.$}>
                <Button
                  size="large"
                  onClick={() => {
                    history.push(warrantyRoute(projectId));
                  }}
                  width={isMobile ? "full" : "none"}
                >
                  View Tracker
                </Button>
              </div>
            </div>
          </div>
        </ModalWrapper>
      )}
    </PageContent>
  );
}

export type SectionProps = {
  form: FormState;
  setForm: Dispatch<SetStateAction<FormState>>;
};

function getStepComponent(
  args: {
    section: section;
    contacts: CreateWarrantyProjectContactFragment[];
    projectId: string;
    user: HomeboundUser;
    itemTypes: CreateWarrantyItemTypeFragment[];
    requestDetailsRef: MutableRefObject<any>;
  } & SectionProps,
) {
  const {
    section,
    contacts,
    projectId,
    user,
    itemTypes,
    requestDetailsRef,
    ...stepArgs
  } = args;

  switch (section) {
    case "Request Details":
      return (
        <RequestDetails
          {...stepArgs}
          itemTypes={itemTypes}
          ref={requestDetailsRef}
        />
      );
    case "Availability":
      return <Availability {...stepArgs} />;
    case "Contact Info":
      return (
        <ContactInfo
          {...stepArgs}
          contacts={contacts}
          projectId={projectId}
          user={user}
        />
      );
  }
}

function formIsValid(form: FormState) {
  const { items, projectContactIds, availabilities } = form;
  return (
    items.length > 0 &&
    projectContactIds.length > 0 &&
    availabilities.length > 0 &&
    items.every((i) => i.description && (i.attachments || []).length > 0)
  );
}
