import { useMediaQuery } from "@material-ui/core";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import { ReactElement, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { Card, Icon } from "src/components";
import { Breakpoints, Css } from "src/Css";
import { careEmail, warrantyEmail } from "src/email";
import { createWarrantyRoute, ProjectRouteProps } from "src/routes";
import { ColoradoFAQData, FAQData, SWFLFAQData } from "../faqData";

type CustomerSupportProps = {
  market: string;
  warrantyEnabled: boolean;
};

export function CustomerSupport(props: CustomerSupportProps) {
  const { projectId } = useParams<ProjectRouteProps>();
  const history = useHistory();
  const { market, warrantyEnabled } = props;
  const isSmall = useMediaQuery(Breakpoints.xs);

  const presentedFaq = useMemo(() => {
    const intitialFaq = FAQData;
    if (market === "Colorado") {
      intitialFaq.push(...(ColoradoFAQData as any));
    }
    if (market === "Southwest Florida") {
      intitialFaq.push(...(SWFLFAQData as any));
    }
    return intitialFaq;
  }, [market]);

  return (
    <div>
      <div css={Css.df.gap2.jcsb.pt4.ifXsOrSm.fdc.$}>
        <Card
          xss={Css.cursorPointer.w50.py3.px4.hPx(124).df.aic.bgWhitePure.ifXsOrSm.w100.py2.px2.$}
          onClick={(e) => {
            window.location.href = `mailto:${warrantyEnabled ? warrantyEmail : careEmail}`;
            e.preventDefault();
          }}
        >
          <div css={Css.df.aic.jcc.hPx(60).wPx(60).br100.bgTaupe.relative.ifXs.hPx(32).wPx(32).$}>
            {isSmall ? (
              <Icon icon="homeThin" viewBox="0 0 16 16" size="16" />
            ) : (
              <Icon icon="email" viewBox="0 0 33 32" width="33" height="32" />
            )}
          </div>
          <div css={Css.df.fdc.w75.pl3.ifXs.pl2.$}>
            <div css={Css.f16.fw5.add("letterSpacing", ".32px").gray800.$}>Email us</div>
            <div css={Css.f16.fw3.gray700.ptPx(4).$}>Send us a note about anything you need help with</div>
          </div>
        </Card>
        {/* Remove the stage condition when releasing */}
        {warrantyEnabled && (
          <Card
            xss={Css.cursorPointer.w50.py3.px4.hPx(124).df.aic.bgWhitePure.ifXsOrSm.w100.py2.px2.$}
            onClick={() => history.push(createWarrantyRoute(projectId!))}
          >
            <div css={Css.df.aic.jcc.hPx(60).wPx(60).br100.bgTaupe.relative.ifXs.hPx(32).wPx(32).$}>
              {isSmall ? (
                <Icon icon="checkPlus" width="19" height="18" viewBox="0 0 19 18" />
              ) : (
                <Icon icon="warranty" size="32" viewBox="0 0 32 32" />
              )}
            </div>
            <div css={Css.df.fdc.w75.pl3.ifXs.pl2.$}>
              <div css={Css.f16.fw5.add("letterSpacing", ".32px").black.$}>Warranty Request</div>
              <div css={Css.f16.fw3.gray700.ptPx(4).$}>Submit an online warranty request and we'll be in touch</div>
            </div>
          </Card>
        )}
      </div>
      <div css={Css.ptPx(56).$}>
        <div css={Css.header20.lh("32px").add("letterSpacing", ".4px").$}>Frequently Asked Questions</div>
        <div css={Css.pt2.df.fdc.gap2.$}>
          {presentedFaq.map((faq) => (
            <FAQCard {...faq} projectId={projectId} key={faq.question} />
          ))}
        </div>
      </div>
    </div>
  );
}

type FAQCardProps = {
  projectId: string;
  question: string;
  answer: string | ((projectId: string) => ReactElement) | ReactElement;
};

function FAQCard(props: FAQCardProps) {
  const [showAnswer, cycleOpen] = useCycle(false, true);
  const { answer, question, projectId } = props;
  const answerElement = typeof answer === "function" ? answer(projectId) : answer;
  return (
    <Card xss={Css.p3.bgWhitePure.$} isClickable={false}>
      <div css={Css.df.jcsb.$}>
        <div css={Css.header18.fw5.add("letterSpacing", ".36px").lh("28px").ifXs.f16.lh("24px").$}>{question}</div>
        <div css={Css.cursorPointer.$}>
          <Icon icon="close" onClick={cycleOpen} xss={Css.if(!showAnswer).add("transform", "rotate(45deg)").$} />
        </div>
      </div>
      <AnimatePresence>
        {showAnswer && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: "auto",
              transition: {
                height: { duration: 0.4 },
                opacity: { duration: 0.25, delay: 0.15 },
              },
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: {
                height: { duration: 0.4 },
                opacity: { duration: 0.25 },
              },
            }}
            css={Css.f14.fw3.lh("22px").gray700.pt1.$}
          >
            {answerElement}
          </motion.div>
        )}
      </AnimatePresence>
    </Card>
  );
}
