import { Menu, MenuItem } from "@material-ui/core";
import { MouseEvent, ReactNode, useEffect, useRef, useState } from "react";
import { Icon } from "src/components/Icons";
import { Css, Only, Padding, px, Xss } from "src/Css";
import { useTestIds } from "src/hooks/useTestIds";

type SwitcherAppBarXss = Xss<Padding>;

export interface SwitcherOptionProps {
  title: string;
  icon: ReactNode;
}

export interface SwitcherAppBarProps<X> {
  options: SwitcherOptionProps[];
  onClick: Function;
  selected?: SwitcherOptionProps;
  xss?: X;
  menuOpen?: boolean; // used for story
  className?: string;
}

export function SwitcherAppBar<X extends Only<SwitcherAppBarXss, X>>(props: SwitcherAppBarProps<X>) {
  const { options, selected, xss, className } = props;
  const [selectedOption, setSelectedOption] = useState(selected || options[0]);
  const { icon: SwitcherTypeIcon } = selectedOption;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const isSwitcherEnabled = options.length > 1;
  const [switcherAppBarId, menuId, menuItemId] = useTestIds("switcherAppBar", ["menu", "menuItem"]);

  useEffect(() => {
    setAnchorEl(menuRef.current);
    if (props.menuOpen) setIsMenuOpen(props.menuOpen);
  }, [menuRef, props.menuOpen]);

  function handleOptionClick(e: MouseEvent) {
    const liElement = e.target as HTMLLIElement;
    const newOption = options.find((o) => o.title === liElement.textContent);
    setIsMenuOpen(false);
    setSelectedOption(newOption!);
    props.onClick(newOption);
  }

  function handleSwitcherClick() {
    if (isSwitcherEnabled) {
      setIsMenuOpen(true);
    }
  }

  return (
    <>
      <div
        css={{ ...Css.df.pt0.pb2.aic.jcsb.if(isSwitcherEnabled).cursorPointer.$, ...xss }}
        onClick={handleSwitcherClick}
        {...switcherAppBarId}
        className={className}
      >
        <div css={Css.df.aic.$} ref={menuRef}>
          {SwitcherTypeIcon}
          <div css={Css.t12up.mx1.$}>{selectedOption.title}</div>
          {isSwitcherEnabled && <Icon icon="chevronDown" xss={Css.w(px(20)).$} />}
        </div>
      </div>
      <Menu
        {...{ anchorEl }}
        {...menuId}
        id="switcher-app-bar-menu"
        keepMounted
        disableRestoreFocus={true}
        open={isMenuOpen}
        getContentAnchorEl={null}
        onClose={() => setIsMenuOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: Css.shadowDark.br3.$,
        }}
      >
        {options.map((o) => {
          return (
            <MenuItem
              css={menuItemStyles}
              key={o.title}
              onClick={handleOptionClick}
              selected={o.title === selectedOption.title}
              {...menuItemId}
            >
              {o.title}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

const menuItemStyles = {
  ...Css.t14.px3.h(px(60)).bTaupe.bt.ttc.$,
  "&:first-of-type": Css.add("borderTop", "none").$,
  "&:hover": Css.bgTransparentGray.$,
};
