import { useMediaQuery } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, Icon, IconButton } from "src/components";
import { Breakpoints, Css, Palette } from "src/Css";
import { JourneyPhaseFragment, ProgressSummaryStatus } from "src/generated/graphql-types";
import { milestoneRoute, milestonesRoute } from "src/routes";
import { getSchedulePhaseInfo, getStatusDisplay } from "../utils/schedulePhaseInfo";

type JourneyProgressHeaderProps = {
  schedulePhases: JourneyPhaseFragment[];
  projectId: string;
};

export function JourneyTimelineHeader(props: JourneyProgressHeaderProps) {
  const { schedulePhases, projectId } = props;
  const isMobile = useMediaQuery(Breakpoints.xs);

  const firstPhaseNotStartedIndex = schedulePhases.findIndex(
    (sp) => sp.progress.scheduleSubPhaseProgress.status === ProgressSummaryStatus.NotStarted,
  );
  const [centeredCardIndex, setCenteredCardIndex] = useState(
    firstPhaseNotStartedIndex === -1 && schedulePhases.length
      ? schedulePhases.length - 1
      : firstPhaseNotStartedIndex - 1 || 0,
  );
  const [leftPx, setLeftPx] = useState(0);
  const history = useHistory();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const fullWidth = containerRef.current.offsetWidth;

      // half of the view, minus half of the expanded card, minus half of the gap between cards and card for each index before the centered card
      setLeftPx(fullWidth / 2 - 266 / 2 - (200 + 16) * centeredCardIndex);
    }
  }, [centeredCardIndex, containerRef]);

  if (schedulePhases.length === 0) {
    return null;
  }

  return (
    <>
      <div css={Css.w("1px").hPx(216).ifXs.hPx(248).$} />
      <div css={Css.df.aic.bgTaupe.absolute.right0.left0.top0.mtPx(isMobile ? 64 : 72).ifXs.df.fdc.$}>
        <div css={Css.dn.mt2.header16.fw7.ifXs.db.$}>Your Build Journey</div>
        <div css={Css.overflowHidden.w100.py4.ifXs.py2.$} ref={containerRef}>
          <div css={Css.relative.leftPx(leftPx).wfc.add("transition", ".1s").$}>
            <div css={Css.df.gap2.aic.wfc.relative.z1.$}>
              {schedulePhases.map((sp, i) => (
                <MilestoneCard
                  expanded={i === centeredCardIndex}
                  schedulePhase={sp}
                  onClick={() => history.push(milestoneRoute(projectId, sp.id))}
                  firstNotStarted={i === firstPhaseNotStartedIndex}
                  key={sp.id}
                />
              ))}
            </div>
            {/* dotted line */}
            <div
              css={
                Css.absolute
                  .top("50%")
                  .add("transform", "translateY(-50%)")
                  .left0.right0.add("borderTop", "2px dotted #000;").z0.$
              }
            />
          </div>
        </div>
        <Link
          to={milestonesRoute(projectId)}
          css={Css.absolute.right4.top2.f14.fw3.lh("22px").gray600.z3.ifXs.position("unset").right0.top0.mb2.$}
        >
          View Details
        </Link>
        {/* fades */}
        <div
          css={
            Css.absolute.top0.bottom0.left0
              .wPx(200)
              .z2.add(
                "backgroundImage",
                "linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 100%)",
              ).ifXs.dn.$
          }
        />
        <div
          css={
            Css.absolute.top0.bottom0.right0
              .wPx(200)
              .z2.add(
                "backgroundImage",
                "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 100%)",
              ).ifXs.dn.$
          }
        />
        <div css={Css.absolute.top("50%").add("transform", "translateY(-50%)").left0.ml2.z3.$}>
          <IconButton
            // Icon button is deep with mui styles and we need to override the background color
            css={Css.important.bgGray700.if(centeredCardIndex === 0).bgGray400.$}
            color="secondary"
            disabled={centeredCardIndex === 0}
            onClick={() => {
              setCenteredCardIndex(centeredCardIndex - 1);
            }}
          >
            <Icon icon="chevronLeft" color={Palette.White} />
          </IconButton>
        </div>
        <div css={Css.absolute.top("50%").add("transform", "translateY(-50%)").right0.mr2.z3.$}>
          <IconButton
            // Icon button is deep with mui styles and we need to override the background color
            css={Css.important.bgGray700.if(centeredCardIndex === schedulePhases.length - 1).bgGray400.$}
            color="secondary"
            disabled={centeredCardIndex === schedulePhases.length - 1}
            onClick={() => {
              setCenteredCardIndex(centeredCardIndex + 1);
            }}
          >
            <Icon icon="chevronRight" color={Palette.White} />
          </IconButton>
        </div>
      </div>
    </>
  );
}

function MilestoneCard({
  schedulePhase,
  onClick,
  firstNotStarted,
  expanded = false,
}: {
  schedulePhase: JourneyPhaseFragment;
  onClick: () => void;
  firstNotStarted: boolean;
  expanded?: boolean;
}) {
  const statusDisplay = getStatusDisplay({ schedulePhase, firstNotStarted });
  const schedulePhaseInfo = getSchedulePhaseInfo(schedulePhase.name);

  return (
    <Card
      onClick={onClick}
      xss={Css.py3.br8.relative.px2.df.aic.wPx(200).hPx(88).bgWhitePure.if(expanded).fdc.py2.hPx(152).wPx(266).$}
    >
      <div css={Css.wPx(40).hPx(40).br100.bgTaupe.df.aic.jcc.$}>{schedulePhaseInfo.svg}</div>
      <div css={Css.header16.fw4.ml1.maxwPx(120).if(expanded).mt1.ml0.add("maxWidth", "none").$}>
        {schedulePhaseInfo.replacementTitle || schedulePhase.name}
      </div>

      <div
        css={{
          ...Css.px("6px").py("2px").add("fontSize", "10px").fw7.lh("14px").br4.absolute.top1.right1.$,
          ...statusDisplay.css,
        }}
      >
        {statusDisplay.title}
      </div>

      {expanded && <div css={Css.darkGrey.f14.fw3.lh("22px").tc.$}>{schedulePhaseInfo.description}</div>}
    </Card>
  );
}
