import { QueryResult } from "@apollo/client";
import React from "react";
import { Css } from "src/Css";

export interface Data<DataT> {
  data: DataT;
}

export type Result<DataT> = Pick<QueryResult<DataT>, "data" | "loading" | "error">;

/**
 * Options for handling Apollo query results
 * DataT - The data type of the result
 * queryResult - The result of your query
 * render - A render method to be called should the result succeed and contain data
 * loading - (optional) A component to use for the loading case
 * error - (optional) A component to use for the error case
 */
interface QueryResultHandlerProps<DataT> {
  result: Result<DataT>;
  render: React.FC<DataT>;
  loading?: React.FC<any>;
  error?: React.FC<Pick<Result<DataT>, "error">>;
}

/**
 * A helper for handling success/loading/error states associated with making Apollo queries with the goal
 * of minimizing boilerplate and increasing consistency in the handling of these cases.
 *
 * A minimalist use case example may look something like:
 *
 * export function MyComponent(props: AuthViewProps) {
 *   const result = useMyQuery(...);
 *   return QueryResultHandler<MyQuery>({
 *     queryResult: result,
 *     render: (data) => {
 *       // Render what you need with the data that came back and the props
 *     },
 *   });
 * }
 *
 * @param props See #QueryResultHandlerProps
 * @constructor
 */
export function QueryResultHandler<DataT>(props: QueryResultHandlerProps<DataT>) {
  const { result, render: RenderComponent, loading: LoadingComponent, error: ErrorComponent } = props;
  const { loading, data, error } = result;
  const dataKeyCount = Object.entries(data || {}).length;

  // Check for the data case first! It is possible we already have data AND loading === true.
  // See context.ts#createApolloClient where we configure default options for loading partial data from the cache
  // and ALSO querying the server
  if (data && dataKeyCount > 0) {
    return RenderComponent(data);
  } else if (error) {
    return ErrorComponent ? (
      <ErrorComponent {...props} error={error} />
    ) : (
      <div>Failed to load data: {error?.message}</div>
    );
  } else if (loading) {
    // Loading or dataKeyCount === 0 case
    return LoadingComponent ? <LoadingComponent {...props} /> : <DefaultLoadingComponent />;
  } else {
    // Not really sure what's happening
    return null;
  }
}

function DefaultLoadingComponent() {
  return <div css={Css.m4.t16.$}>Loading....</div>;
}
