import { useMediaQuery } from "@material-ui/core";
import { format } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import { Card, Data, Icon, IconButton, LinkButton, QueryResultHandler } from "src/components";
import { shouldShowSelections } from "src/components/Nav";
import { Breakpoints, Css } from "src/Css";
import { InboxItemStatus, OverviewPageQuery, ProjectStatus, useOverviewPageQuery } from "src/generated/graphql-types";
import { homeownerNoteRoute, ProjectRouteProps, spacesRoute, v2SelectionsRoute } from "src/routes";
import { compareDates } from "src/utils";
import { OverviewCard } from "./components/OverviewCard";
import { UpdatesFeed } from "./components/UpdatesFeed";

export function OverviewPage() {
  const { projectId } = useParams<ProjectRouteProps>();

  const result = useOverviewPageQuery({ variables: { projectId } });
  return QueryResultHandler<OverviewPageQuery>({
    result: result,
    render: (data) => <OverviewDataView {...{ data }} />,
  });
}

export function OverviewDataView({ data }: Data<OverviewPageQuery>) {
  const history = useHistory();
  const smallAndDown = useMediaQuery(Breakpoints.smAndDown);
  const isMobile = useMediaQuery(Breakpoints.xs);

  const homeownerProject = data?.homeownerProject;
  const warrantyEnabled = homeownerProject.status?.code === ProjectStatus.UnderWarranty;

  const cards = [];
  if (homeownerProject.stage.progress.taskProgress) {
    const progress = data?.homeownerProject.stage.progress.taskProgress;
    cards.push({
      icon: "homeThin" as const,
      title: percentComplete(progress.completed, progress.total),
      subTitle: "Build Completion",
    });
  }
  if (homeownerProject.homeownerDeliveryDate) {
    // find quarter based off date
    const deliveryDate = new Date(homeownerProject.homeownerDeliveryDate);
    const estimatedDate = `Q${format(deliveryDate, "q yyyy")}`;
    cards.push({
      icon: "keys" as const,
      title: estimatedDate,
      subTitle: "Estimated Delivery",
    });
    // find the months until move in
    const monthsUntilMoveIn = getMonthsUntilMoveIn(deliveryDate);
    if (monthsUntilMoveIn >= 0) {
      cards.push({
        icon: "clock" as const,
        title: monthsUntilMoveIn === 1 ? `~${monthsUntilMoveIn} month` : `~${monthsUntilMoveIn} months`,
        subTitle: "Until you move in!",
      });
    }
  }
  cards.push({
    icon: "checkPlus" as const,
    title:
      homeownerProject.tasksCompletedThisWeek + (homeownerProject.tasksCompletedThisWeek === 1 ? " task" : " tasks"),
    subTitle: "Completed this week",
  });

  const latestUpdate = [...homeownerProject.homeownerNotes].sort((a, b) => compareDates(b.createdAt, a.createdAt))[0];

  const items = homeownerProject.homeownerInbox?.items;
  const modifiedItems = items.filter((item) => item.status !== InboxItemStatus.Archived).slice(0, 5);
  const defaultImg = "/images/default-cover-image.jpg";
  const address = homeownerProject.buildAddress.street1;

  const readyPlanConfig = homeownerProject.readyPlanConfig;
  // readyPlan name remove parenthesis and characters inside
  const readyPlanName = readyPlanConfig?.readyPlan?.name.replace(/ *\([^)]*\) */g, "");
  const beds = readyPlanConfig?.programData?.bedrooms;
  const baths = readyPlanConfig?.programData?.fullBaths || 0 + 0.5 * (readyPlanConfig?.programData?.halfBaths || 0);
  const sqft = homeownerProject.readyPlanConfig?.programData?.sellableSqft;

  const showSelections = shouldShowSelections(homeownerProject as any);
  const showV2SelectionsPageFlag = !!homeownerProject.checkoutCustomerConfigId;

  const fullWidth = smallAndDown ? "1 / -1" : "2 / -2";
  const journeyWidth = isMobile ? "1 / -1" : smallAndDown ? "1 / 6" : "2 / 8";
  const inboxWidth = isMobile ? "1 / -1" : smallAndDown ? "6 / -1" : "8 / -2";

  return (
    <>
      <div css={Css.gc(fullWidth).$}>
        <div css={Css.header24.lh("40px").pt4.$}>Overview</div>
        <div css={Css.t14.fYellow300.lh("22px").$}>{address}</div>
      </div>
      {!warrantyEnabled && (
        <div
          css={
            Css.pyPx(10).mhPx(140).pxPx(6).df.gap2.gc(fullWidth).overflowAuto.add("WebkitOverflowScrolling", "touch").$
          }
        >
          {cards.map((card) => (
            <OverviewCard key={card.title} {...card} />
          ))}
        </div>
      )}
      <div css={Css.gc(fullWidth).$}>
        <div css={Css.header20.pb1.$}>Your Home</div>
        <div
          css={Css.shadowBasic.br8.bgWhitePure.dg.gtc("6fr 4fr").gap3.ifSmAndDown.add("gridTemplate", "auto / auto").$}
        >
          <div>
            <img
              src={data.homeownerProject.coverImage?.downloadUrl || defaultImg}
              alt="Overview"
              css={[
                {
                  objectFit: "cover",
                },
                Css.w100.h100.br8.$,
              ]}
            />
          </div>

          <div css={Css.flex(3).df.fdc.pt5.px5.ifXs.pt2.px3.$}>
            {showSelections ? (
              <LinkButton
                to={
                  showV2SelectionsPageFlag ? v2SelectionsRoute(homeownerProject.id) : spacesRoute(homeownerProject.id)
                }
              >
                <div css={Css.df.aic.f16.fw5.gray800.lh("24px").add("letterSpacing", "0.32px").$}>
                  {readyPlanName} <Icon icon="chevronRight" />
                </div>
              </LinkButton>
            ) : (
              <div css={Css.df.aic.f16.fw5.gray800.lh("24px").add("letterSpacing", "0.32px").$}>{readyPlanName}</div>
            )}
            <div css={Css.f14.fw3.gray700.lh("22px").pt2.$}>{readyPlanConfig?.readyPlan?.description}</div>
            <div css={Css.pt5.pb6.df.gap8.f12.fYellow400.lh("28px").gray700.ifXs.pt1.pb2.gap3.$}>
              {beds && (
                <div css={Css.df.fdc.gap1.$}>
                  {!isMobile && <Icon icon="bed" viewBox="0 0 26 18" width="28" height="20" />}
                  <div>{beds} beds</div>
                </div>
              )}
              {baths !== 0 && (
                <div css={Css.df.fdc.gap1.$}>
                  {!isMobile && <Icon icon="bath" viewBox="0 0 34 18" width="36" height="20" />}
                  <div>{baths} Baths</div>
                </div>
              )}
              {sqft && (
                <div css={Css.df.fdc.gap1.$}>
                  {!isMobile && <Icon icon="square" width="24" height="20" viewBox="0 0 24 20" />}
                  <div>{sqft} Sq Ft</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {latestUpdate && (
        <div css={Css.gc(journeyWidth).mb6.$}>
          <div css={Css.header20.lh("32px").$}>Latest Journey Update</div>
          <Card xss={Css.mt1.br8.bgWhitePure.$} isClickable={false}>
            {latestUpdate.jobLogImages[0]?.asset.previewUrl && (
              <img
                css={Css.w100.objectCover.maxhPx(340).$}
                src={latestUpdate.jobLogImages[0].asset.previewUrl!}
                alt={latestUpdate.title}
              />
            )}
            <div
              css={Css.mx3.my2.df.aic.cursorPointer.$}
              onClick={() => history.push(homeownerNoteRoute(data?.homeownerProject.id, latestUpdate.id))}
            >
              <div css={Css.df.fdc.mra.$}>
                <div css={Css.f18.gray800.fw5.lh("28px").add("letterSpacing", "0.36px").$}>{latestUpdate.title}</div>
                <div css={Css.f16.gray700.fw3.lh("24px").add("letterSpacing", "unset").$}>
                  View your latest journey update here
                </div>
              </div>
              <IconButton>
                <Icon icon="chevronRight" />
              </IconButton>
            </div>
          </Card>
        </div>
      )}
      <div css={Css.gc(latestUpdate ? inboxWidth : fullWidth).mb6.$}>
        <div css={Css.header20.lh("32px").$}>Inbox</div>
        <div css={Css.shadowBasic.br8.mt1.h("calc(100% - 40px)").overflowHidden.$}>
          <UpdatesFeed items={modifiedItems} />
        </div>
      </div>
    </>
  );
}

function percentComplete(completed: number, total: number) {
  if (total === 0) return "0%";

  const percent = (completed / total) * 100;
  if (percent.toString().includes(".")) {
    return `${percent.toFixed(0)}%`;
  }
  return `${percent}%`;
}

export function getMonthsUntilMoveIn(deliveryDate: Date) {
  const today = new Date();
  // if todays date is after the delivery date, return -1
  if (today > new Date(deliveryDate)) {
    return -1;
  }
  const todaysMonth = today.getMonth() + 1;
  const todaysYear = today.getFullYear();
  const deliveryMonth = deliveryDate.getMonth() + 1;
  const deliveryYear = deliveryDate.getFullYear();
  const monthsUntilMoveIn = (deliveryYear - todaysYear) * 12 + (deliveryMonth - todaysMonth);
  return monthsUntilMoveIn;
}
