import { documentsRoute, financesRoute, journeyRoute } from "src/routes";

export const FAQData = [
  {
    question: "Is my project still on schedule?",
    answer: (projectId: string) => (
      <div>
        You can see your project’s status by going to your <a href={journeyRoute(projectId)}>Journey page in HOP</a>. If
        you have any concerns about the timeline of your project, please reach out to your CXA directly
      </div>
    ),
  },
  {
    question: "What is my current account balance?",
    answer: (projectId: string) => (
      <div>
        You can see your current account balance under the <a href={financesRoute(projectId)}>Finances page in HOP</a>.
        Please note that whether you pay via check, wire, or online payment there may be a lag between your payment and
        it showing in our system. If you have any concerns about your account status, please reach out to your CXA as
        soon as possible to avoid late payments and interest fees.{" "}
      </div>
    ),
  },
  {
    question: "When can I take measurements for curtains/furniture?",
    answer:
      "While your plan set will list dimensions, we ask that you wait until your drywall is finished to take measurements. Exact dimensions may shift slightly during construction. Waiting until your drywall has been installed will give you the most accurate measurements of your home. ",
  },
];

export const ColoradoFAQData = [
  {
    question: "What foundation type will my home require? When will I receive the cost of my foundation?",
    answer:
      "The foundation type required for your home is determined by the soil conditions of your lot. Once we have the geotechnical report back we’ll be able to tell you what foundation type is required. We work with a trade partner to build your foundation, and we get the quote for its cost from them. They need your completed plan set before they can assemble their quote. It typically takes them 2-3 weeks after your site specific plans are completed and submitted for permit to give us the quote. We will send you that quote when we receive it. The cost for your foundation will be included in your contract addendum.",
  },
  {
    question: "Can I opt out of landscaping?",
    answer:
      "Front yard landscaping is built into the base price of the home and can not be customized or changed in any way. Landscaping for your rear and side yards is not included.",
  },
  {
    question: "Am I eligible for any grant funding?",
    answer: (
      <div>
        For help navigating grant funding, we recommend you start by reaching out to{" "}
        <a href="https://bouldercountynavigatingdisaster.gov/">Navigating Disaster for Boulder County</a>. They have
        grant navigators who can help you learn more about what you may qualify for and how to apply.{" "}
      </div>
    ),
  },
  {
    question: "Do I need to install my mailbox or does Homebound do that?",
    answer: "Homebound installs mailboxes in areas that require one. Sagamore does not require mailboxes.",
  },
  {
    question: "How do I pay my permit fee?",
    answer:
      "Homebound pays your permit fee when we apply for your permit. We will charge you for it as a separate line item on your second invoice.",
  },
  {
    question: "What is the process for getting my utilities turned on?",
    answer:
      "As the homeowner, you will have to reach out to your utility provider to request that they transfer services into your name before you move in.",
  },
];

export const SWFLFAQData = [
  {
    question: "Will my foundation require pilings?",
    answer:
      "This is determined by both the soil conditions of your lot and the coastal zone you fall into. Once we have the site engineering complete, we’ll be able to let you know whether or not your home will require pilings.",
  },
  {
    question: "How do I pay my permit fee / How will I know when I need to pay my permit fee?",
    answer:
      "You will have two separate permit fees you need to pay – one for your home and one for your driveway. When your permit fees are ready for you to pay, your CXA will send you a link to each fee. You will be able to pay them both online through the city’s permit portal.",
  },
  {
    question: "Am I responsible for utility bills during construction?",
    answer:
      "Yes, clients are responsible for all of the utility bill expenses incurred during construction. This is specified in Section 5 of the contract general provisions.",
  },
];
