import React, { ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "src/components/Icons";
import { Css, Margin, Only, Palette, Properties, px } from "src/Css";
import { useTestIds } from "src/hooks";
import tinycolor from "tinycolor2";

/** A helper for making `Row` type aliases of simple/flat tables that are just header + data. */
export type SimpleHeaderAndDataOf<T> = { kind: "header" } | ({ kind: "data" } & T);

/** A const for a marker header row. */
export const simpleHeader = { kind: "header" as const, id: "header" };

type SortFlags<S> = [S, Direction];

export type Kinded = { kind: string };

export type GridTableXss = Pick<Properties, Margin>;

export type Direction = "ASC" | "DESC";

export interface GridTableProps<R extends Kinded, S, X> {
  /** The column definitions i.e. how each column should render each row kind. */
  columns: GridColumn<R, S>[];
  /** The rows of data (including any header/footer rows), to be rendered by the column definitions. */
  rows: GridDataRow<R>[];
  /** Optional row-kind-level styling / behavior like onClick/rowLinks. */
  rowStyles?: GridRowStyles<R>;
  /** Whether the header row should be sticky. */
  stickyHeader?: boolean;
  sorting?: "client-side" | "server-side";
  /** The current sort by value + direction (if server-side sorting). */
  sort?: [S, Direction];
  /** Callback for when the column is sorted (if server-side sorting). */
  onSort?: (orderBy: S, direction: Direction) => void;
  /** Shown in the first row slot, if there are no rows to show, i.e. 'No rows found'. */
  fallbackMessage?: string;
  /** Shown in the first row, kinda-like the fallbackMessage, but shown even if there are rows as well. */
  infoMessage?: string;
  /** Applies a client-side filter to rows, using either it's text value or `GridCellContent.value`. */
  filter?: string;
  /** Caps the client-side filter to a max number of rows. */
  filterMaxRows?: number;
  xss?: X;
}

/**
 * Renders data in our table layout.
 *
 * Tables are essentially a matrix of columns and rows, and this `GridTable` API is setup
 * such that:
 *
 * - Rows are mostly data, tagged with a given `kind`
 *   - I.e. this handles tables with nested/non-homogeneous rows because you can have a
 *     row with `kind: "parent"` and another with `kind: "child"`
 * - Columns are mostly rendering logic
 *   - I.e. each column defines it's behavior for each given row `kind`
 *
 * In a kind of cute way, headers are not modeled specially, i.e. they are just another
 * row `kind` along with the data rows. (Admittedly, out of pragmatism, we do apply some
 * special styling to the row that uses `kind: "header"`.)
 */
export function GridTable<R extends Kinded, S extends {} = {}, X extends Only<GridTableXss, X> = {}>(
  props: GridTableProps<R, S, X>,
) {
  const {
    columns,
    rows,
    rowStyles,
    stickyHeader = false,
    xss,
    sorting,
    sort,
    onSort,
    filter,
    filterMaxRows,
    fallbackMessage = "No rows found.",
    infoMessage,
  } = props;

  const gridTemplateColumns = columns
    // Default to auto, but use `c.w` as a fr if numeric or else `c.w` as-if if a string
    .map((c) => (typeof c.w === "string" ? c.w : c.w !== undefined ? `${c.w}fr` : "auto"))
    .join(" ");

  const [sortFlags, setSortValue] = useSortFlags<R, S>(columns, sort, onSort);
  const maybeSorted = useMemo(() => {
    if (sorting === "client-side" && sortFlags) {
      // If using client-side sort, the sortFlags use S = number
      return sortRows(columns, rows, sorting, sortFlags as any as SortFlags<number>);
    }
    return rows;
  }, [columns, rows, sorting, sortFlags]);
  const noData = !rows.some((row) => row.kind !== "header");

  // For each row, keep it's ReactNode (React.memo'd) + its filter values, so we can re-filter w/o re-evaluating this useMemo.
  const allRows: [R["kind"], ReactNode, Array<ReactNode | GridCellContent>][] = useMemo(() => {
    return maybeSorted.map((row) => {
      // We only pass sortProps to header rows, b/c non-headers rows shouldn't have to re-render on sorting
      // changes, and so by not passing the sortProps, it means the data rows' React.memo will still cache them.
      const sortProps = row.kind === "header" ? { sorting, sortFlags, setSortValue } : {};
      const rowElement = (
        <MemoizedGridRow<R, S>
          key={`${row.kind}-${row.id}`}
          {...{ columns, row, rowStyles, stickyHeader, ...sortProps }}
        />
      );
      const filterValues = columns.map((c) => applyRowFn(c, row));
      return [row.kind, rowElement, filterValues];
    });
  }, [maybeSorted, columns, rowStyles, setSortValue, sortFlags, sorting, stickyHeader]);

  // Split out the header rows from the data rows so that we can put an `infoMessage` in between them (if needed).
  const headerRows = allRows.filter(([kind]) => kind === "header");

  // Break up "foo bar" into `[foo, bar]` and a row must match both `foo` and `bar`
  const filters = (filter && filter.split(/ +/)) || [];
  let filteredRows = allRows.filter(
    ([kind, , rowValues]) =>
      kind !== "header" &&
      (filters.length === 0 ||
        filters.every((filter) => rowValues.some((maybeContent) => matchesFilter(maybeContent, filter)))),
  );

  let tooManyClientSideRows = false;
  if (filterMaxRows && filteredRows.length > filterMaxRows) {
    tooManyClientSideRows = true;
    filteredRows = filteredRows.slice(0, filterMaxRows);
  }

  const firstRowMessage =
    (noData && fallbackMessage) || (tooManyClientSideRows && "Hiding some rows, use filter...") || infoMessage;

  return (
    <div
      css={{
        ...Css.dg.add({ gridTemplateColumns }).$,
        ...xss,
      }}
      data-testid="grid-table"
    >
      {headerRows.map(([, node]) => node)}
      {/* Show an all-column-span info message if it's set. */}
      {firstRowMessage && (
        <div css={Css.add("gridColumn", `${columns.length} span`).$}>
          <div css={Css.px1.py2.$}>{firstRowMessage}</div>
        </div>
      )}
      {filteredRows.map(([, node]) => node)}
    </div>
  );
}

// See https://stackoverflow.com/a/50125960/355031
type DiscriminateUnion<T, K extends keyof T, V extends T[K]> = T extends Record<K, V> ? T : never;

/** Defines how a single column will render each given row `kind` in `R`. */
export type GridColumn<R extends Kinded, S = {}> = {
  [P in R["kind"]]: string | ((row: DiscriminateUnion<R, "kind", P>) => ReactNode | GridCellContent);
} & {
  /** The column's grid column width, defaults to `auto`. */
  w?: number | string;
  /** The column's default alignment for each cell. */
  align?: GridCellAlignment;
  /** Whether the column can be sorted (if client-side sorting). */
  sort?: boolean;
  /** This column's sort by value (if server-side sorting). */
  sortValue?: S;
};

/** Allows rendering a specific cell. */
type RenderCellFn<R> = (
  idx: number,
  css: Properties,
  content: ReactNode,
  row: R,
  rowStyle: RowStyle<R> | undefined,
) => ReactNode;

/** Defines row-specific styling for each given row `kind` in `R` */
export type GridRowStyles<R extends Kinded> = {
  [P in R["kind"]]: RowStyle<DiscriminateUnion<R, "kind", P>>;
};

export interface RowStyle<R> {
  /** Applies this css to the wrapper row, i.e. for row-level hovers. */
  rowCss?: Properties | ((row: R) => Properties);
  /** Applies this css to each cell in the row. */
  cellCss?: Properties | ((row: R) => Properties);
  /** Renders the cell element, i.e. a link to get whole-row links. */
  renderCell?: RenderCellFn<R>;
  /** Whether the row should be indented (via a style applied to the 1st cell). */
  indent?: "1" | "2";
  /** Whether the row should be a link. */
  rowLink?: (row: R) => string;
  /** Fired when the row is clicked, similar to rowLink but for actions that aren't 'go to this link'. */
  onClick?: (row: R) => void;
}

function getIndentationCss<R>(rowStyle: RowStyle<R> | undefined): Properties {
  const indent = rowStyle?.indent;
  return Css.pl(indent === "2" ? 7 : indent === "1" ? 4 : 1).$;
}
export type GridCellAlignment = "left" | "right" | "center";

export type GridCellContent = { content: ReactNode; alignment?: GridCellAlignment; value?: number | string | Date };

/**
 * The data for any row in the table, marked by `kind` so that each column knows how to render it.
 *
 * This should contain very little presentation data, i.e. the bulk of the keys should be data for
 * the given `kind: ...` of each row (which we pull in via the `DiscriminateUnion` intersection).
 *
 * The presentation concerns instead live in each GridColumn definition, which formats a given
 * data row for its column.
 */
export type GridDataRow<R extends Kinded> = {
  kind: R["kind"];
  /** Combined with the `kind` to determine a table wide React key. */
  id: string;
} & DiscriminateUnion<R, "kind", R["kind"]>;

interface GridRowProps<R extends Kinded, S> {
  columns: GridColumn<R>[];
  row: GridDataRow<R>;
  rowStyles: GridRowStyles<R> | undefined;
  stickyHeader: boolean;
  sorting?: string;
  sortFlags?: SortFlags<S>;
  setSortValue?: (value: S) => void;
}

// We extract GridRow to its own mini-component primarily so we can React.memo'ize it.
function GridRow<R extends Kinded, S>(props: GridRowProps<R, S>) {
  const { columns, row, rowStyles, stickyHeader, sorting, sortFlags, setSortValue } = props;

  // We treat the "header" kind as special for "good defaults" styling
  const isHeader = row.kind === "header";
  const rowStyle = rowStyles?.[row.kind];

  const rowStyleCellCss = maybeApplyFunction(row, rowStyle?.cellCss);
  const rowCss = {
    ...Css.display("contents").$,
    ...((rowStyle?.rowLink || rowStyle?.onClick) && {
      // Even though backgroundColor is set on the cellCss (due to display: content), the hover target is the row.
      "&:hover > *": Css.cursorPointer.bgColor(maybeDarken(rowStyleCellCss?.backgroundColor, Palette.Gray200)).$,
    }),
    ...maybeApplyFunction(row, rowStyle?.rowCss),
  };

  return (
    <div css={rowCss}>
      {columns.map((column, idx) => {
        const maybeContent = applyRowFn(column, row);

        const canSortColumn =
          (sorting === "client-side" && column.sort !== false) || (sorting === "server-side" && !!column.sortValue);
        const content = wrapIfOnlyString(maybeContent, isHeader, canSortColumn);

        const cellCss = {
          // Adding display flex so we can align content within the cells
          ...Css.df.px3.jc(getJustification(column, maybeContent, isHeader, idx)).$,
          // Change the padding a little for the first column
          ...(idx === 0 && getIndentationCss(rowStyle)),
          ...(isHeader ? Css.asfe.nowrap.py1.$ : Css.py2.$),
          // Use h100 so that all cells are the same height when scrolled; set bgWhite for when we're laid over other rows.
          ...(isHeader && stickyHeader && Css.sticky.bgWhite.top0.h100.aife.$),
          ...rowStyleCellCss,
        };

        const renderFn: RenderCellFn<any> =
          rowStyle?.renderCell || rowStyle?.rowLink
            ? rowLinkRenderFn
            : isHeader
            ? headerRenderFn(columns, column, sortFlags, setSortValue)
            : rowStyle?.onClick
            ? rowClickRenderFn
            : defaultRenderFn;

        return renderFn(idx, cellCss, content, row, rowStyle);
      })}
    </div>
  );
}

// Fix to work with generics, see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-656596623
const MemoizedGridRow = React.memo(GridRow) as typeof GridRow;

/** If a column def return just string text for a given row, apply some default styling. */
function wrapIfOnlyString(content: ReactNode | GridCellContent, isHeader: boolean, sorting: boolean): ReactNode {
  if (typeof content === "string") {
    if (sorting) {
      return <SortHeader {...{ content }} />;
    } else {
      return <div>{content}</div>;
    }
  } else if (isContentAndSettings(content)) {
    return content.content;
  }
  return content;
}

function isContentAndSettings(content: ReactNode | GridCellContent): content is GridCellContent {
  return typeof content === "object" && !!content && "content" in content;
}

/** Return the content for a given column def applied to a given row. */
function applyRowFn<R extends Kinded>(column: GridColumn<R>, row: GridDataRow<R>): ReactNode | GridCellContent {
  // Usually this is a function to apply against the row, but sometimes it's a hard-coded value, i.e. for headers
  const maybeContent = column[row.kind];
  return typeof maybeContent === "function" ? (maybeContent as Function)(row) : maybeContent;
}

/** Renders our default cell element, i.e. if no row links and no custom renderCell are used. */
const defaultRenderFn: RenderCellFn<any> = (key, css, content) => (
  <div key={key} css={{ ...css }}>
    {content}
  </div>
);

/** Exposes the GridTable settings to components, currently just for exposing sorting settings. */
interface GridContextProps {
  // only makes sense for the column context
  sorted: "ASC" | "DESC" | undefined;
  toggleSort(): void;
}

const GridContext = React.createContext<GridContextProps>({ sorted: undefined, toggleSort: () => {} });
const { Provider } = GridContext;

/** Sets up the `GridContext` so that header cells can access the current sort settings. */
const headerRenderFn: (
  columns: GridColumn<any>[],
  column: GridColumn<any>,
  sortFlags: SortFlags<any> | undefined,
  setSortValue: Function | undefined,
) => RenderCellFn<any> = (columns, column, sortFlags, setSortValue) => (key, css, content) => {
  const [currentValue, direction] = sortFlags || [];
  const newValue = column.sortValue || columns.indexOf(column);
  const context: GridContextProps = {
    sorted: newValue === currentValue ? direction : undefined,
    toggleSort: () => setSortValue!(newValue),
  };
  return (
    <Provider key={key} value={context}>
      <div css={{ ...css }}>{content}</div>
    </Provider>
  );
};

/** Renders a cell element when a row link is in play. */
const rowLinkRenderFn: RenderCellFn<any> = (key, css, content, row, rowStyle) => {
  const to = rowStyle!.rowLink!(row);
  return (
    <Link key={key} to={to} css={{ ...Css.noUnderline.color("unset").$, ...css }}>
      {content}
    </Link>
  );
};

/** Renders a cell that will fire the RowStyle.onClick. */
const rowClickRenderFn: RenderCellFn<any> = (key, css, content, row, rowStyle) => {
  return (
    <div key={key} css={{ ...css }} onClick={() => rowStyle!.onClick!(row)}>
      {content}
    </div>
  );
};

const alignmentToJustify: Record<GridCellAlignment, Properties["justifyContent"]> = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};

// For alignment, use: 1) user-specified, else 2) center if non-1st header, else 3) left.
function getJustification(
  column: GridColumn<any>,
  maybeContent: ReactNode | GridCellContent,
  isHeader: boolean,
  idx: number,
): Properties["justifyContent"] {
  const alignment =
    (isContentAndSettings(maybeContent) && maybeContent.alignment) ||
    column.align ||
    (isHeader && idx > 0 ? "center" : "left");
  return alignmentToJustify[alignment];
}

// TODO This is very WIP / proof-of-concept and needs flushed out a lot more to handle nested batches.
function sortRows<R extends Kinded>(
  columns: GridColumn<R>[],
  rows: GridDataRow<R>[],
  sorting: "client-side" | "server-side" | undefined,
  sortFlags: SortFlags<number>,
) {
  const sorted: GridDataRow<R>[] = [];
  let currentKind: R["kind"] | undefined = undefined;
  let currentBatch: GridDataRow<R>[] = [];

  for (const row of rows) {
    if (row.kind === "header") {
      sorted.push(row);
    } else if (row.kind === currentKind) {
      currentBatch.push(row);
    } else {
      sortBatch(columns, currentBatch, sortFlags);
      sorted.push(...currentBatch);
      currentBatch = [row];
      currentKind = row.kind;
    }
  }

  // Flush last batch
  sortBatch(columns, currentBatch, sortFlags);
  sorted.push(...currentBatch);

  return sorted;
}

function sortBatch<R extends Kinded>(
  columns: GridColumn<R>[],
  batch: GridDataRow<R>[],
  sortFlags: SortFlags<number>,
): void {
  // When client-side sort, the sort value is the column index
  const [value, direction] = sortFlags;
  const column = columns[value];
  const invert = direction === "DESC";
  batch.sort((a, b) => {
    const v1 = maybeValue(applyRowFn(column, a));
    const v2 = maybeValue(applyRowFn(column, b));
    const v1e = v1 === null || v1 === undefined;
    const v2e = v2 === null || v2 === undefined;
    if ((v1e && v2e) || v1 === v2) {
      return 0;
    } else if (v1e || v1 < v2) {
      return invert ? 1 : -1;
    } else if (v2e || v1 > v2) {
      return invert ? -1 : 1;
    }
    return 0;
  });
}

function maybeValue(value: ReactNode | GridCellContent): any {
  return value && typeof value === "object" && "value" in value ? value.value : value;
}

/** Small custom hook that wraps the "setSortColumn inverts the current sort" logic. */
function useSortFlags<R extends Kinded, S>(
  columns: GridColumn<R, S>[],
  sort: [S, Direction] | undefined,
  onSort: ((sortValue: S, direction: Direction) => void) | undefined,
): [SortFlags<S> | undefined, (value: S) => void] {
  // If we're server-side sorting, use the caller's `sort` prop to initialize our internal `useState`.
  // Note that after this initialization, our `setSortValue` will keep both the caller's state
  // and our internal sortFlags state in sync. As long as the caller doesn't change the sort prop
  // directly.
  const [sortFlags, setSortFlags] = useState<SortFlags<S> | undefined>(sort);

  // Make a custom setSortValue that is useState-like but contains the invert-if-same-column-clicked-twice logic.
  const setSortValue = useCallback(
    (value: S) => {
      const [currentValue, currentDirection] = sortFlags || [];
      const [newValue, newDirection] =
        value === currentValue
          ? [currentValue!, currentDirection === "ASC" ? ("DESC" as const) : ("ASC" as const)]
          : [value, "ASC" as const];
      setSortFlags([newValue, newDirection]);
      if (onSort) {
        // The sortValue! should be safe because we check it before display the sort icon
        onSort(newValue, newDirection);
      }
    },
    [sortFlags, setSortFlags, onSort],
  );

  return [sortFlags, setSortValue];
}

export function SortHeader(props: { content: string; xss?: Properties }) {
  const { content, xss } = props;
  const { sorted, toggleSort } = useContext(GridContext);
  const [testId] = useTestIds("sortHeader", ["icon"]);
  return (
    <div {...testId} css={{ ...Css.cursorPointer.selectNone.df.aic.$, ...xss }} onClick={toggleSort}>
      {content}
      <div css={Css.h(px(24)).w(px(24)).$}>
        {sorted === "ASC" && <Icon icon="chevronUp" />}
        {sorted === "DESC" && <Icon icon="chevronDown" />}
      </div>
    </div>
  );
}

function maybeApplyFunction<T>(
  row: T,
  maybeFn: Properties | ((row: T) => Properties) | undefined,
): Properties | undefined {
  return typeof maybeFn === "function" ? maybeFn(row) : maybeFn;
}

export function matchesFilter(maybeContent: ReactNode | GridCellContent, filter: string): boolean {
  let value = maybeValue(maybeContent);
  if (typeof value === "string") {
    return value.toLowerCase().includes(filter.toLowerCase());
  } else if (typeof value === "number") {
    return Number(filter) === value;
  }
  return false;
}

function maybeDarken(color: string | undefined, defaultColor: string): string {
  return color ? tinycolor(color).darken(3).toString() : defaultColor;
}
